import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from "@mui/icons-material/Close";
import ReactGA from 'react-ga4';

//import data from "./data/sexual";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // maxWidth: 640,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Resource({data, resourceTitle, resourceName}) {

  const [openModalId, setOpenModalId] = useState(null);

  const toggleModal = (videoId) => {
    setOpenModalId((prev) => (prev === videoId ? null : videoId));
  };

  return(
    <>
    {ReactGA.send({ hitType: "pageview", page: `/resource/${resourceName}`, title: `RESOURCE: ${resourceName}`})}
    <Container sx={{ mt: 3 }}>
    <Grid container spacing={3} sx={{ mb: 25 }} key={resourceTitle}>
      <Grid item xs={12} lg={12}>
      <h1>{resourceName}</h1>
        <Grid container spacing={3}>
          {data?.map(({ title, description, videoId, url }) => (
            <Grid item xs={12} md={4} sx={{ mb: 2 }} key={title}>
                <Card sx={{ cursor: "pointer" }} onClick={()=> toggleModal(videoId)}>
                <h3>{title}</h3>
                </Card>
              <Modal
        open={openModalId === videoId}
        onClose={()=> toggleModal(videoId)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModalId === videoId} timeout={500}>
        <Box xs={4} md={4} sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
            <CloseIcon fontSize="medium" sx={{ cursor: "pointer", color:"#EC407A", position: "absolute", right: 0, mr: '5%' }} onClick={()=>toggleModal(videoId)} />
          </Typography>
          
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {description}
          </Typography>
          <Typography component="a" href={url} target="_blank" id="modal-modal-description" sx={{ mt: 2 }}>
            {url}
          </Typography>
        </Box>
        </Fade>
      </Modal>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
    </Container>
    </>
  );
}

export default Resource;