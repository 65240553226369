import { Container } from '@mui/system';

// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ReactGA from 'react-ga4';

function Cards() {
  ReactGA.send({ hitType: "pageview", page: "/cards", title: "Cards" });
  const cards = [
    {
      name: "Planning",
      text: "This is an innovative project, aimed at supporting medical schools in areas of conflict and/or natural disasters who are unable to provide onsite medical education safely, and do not currently have adequate online learning provision.\nWhen teaching sessions are cancelled, medical students in difficulty across the globe are at risk of being unable to qualify as doctors.\nThe MSID team have created an online learning platform focussing on curriculum topics to enable medical students to access and pursue their learning even during times of great difficulty.",
      id: 1
    },
    {
      name: "Development",
      text: "The impetus for this project came in October 2023 when medical students from the Arab American University of Palestine (AAUP) asked for support as they were unable to see patients in healthcare settings owing to the safety risk.\nThe project saw over 300 volunteers work together to develop an online platform which is accessible to the medical students from a place of safety. Presenters from the MSID team each recorded a short (10-15 minute) video covering a patient presentation from the UK Medical Licensing Assessment (MLA) content map, covering BMJ Best Practice principles of history taking, physical examination, emergency considerations and differential diagnoses. The success of the MSID platform relies on collaboration between healthcare professionals, medical educators and IT professionals.\nThe platform includes a section where a variety of external resources have been compiled that may also support medical students in difficulty.",
      id: 2
    },
    {
      name: "Quality Assurance",
      text: "MSID has used the UK MLA content map to form the basis of all patient presentations. The MLA has been developed by the UK General Medical Council (GMC) and is considered the standard for medical education training in the UK.\nAll video recordings are reviewed by GMC-registered UK specialists (Family Medicine or Hospital Consultants) to ensure the MSID website provides a high standard of quality in medical education provision.\nThe MSID team believes that learning is accessible when it is inclusive. Our team are from various cultures and countries, enabling us to form a truly unique and inclusive ‘community of practice’.",
      id: 3
    },
    {
      name: "Sustainability",
      text: "In October 2023, the MSID team identified 16 out of the 24 areas of clinical practice from the UK MLA as Priority Level 1 learning needs for medical students in difficulty, and these were addressed first on this learning platform.\nIn November 2023, the MSID team expanded its content to cover Priority Level 2 topics, thereby covering all of the 200+ patient presentations in the curriculum.\nThis unique resource aspires to be comprehensive in order to anticipate and address the ongoing learning needs of medical students in difficulty. The contact form on this website enables healthcare professionals who are interested in contributing content to this platform to join our team and develop resources, which will now be focussed on clinical and professional capabilities.",
      id: 4
    },
    {
      name: "Dissemination",
      text: "We want visitors to this website to share this resource with learners in difficulty in order to achieve the MSID mission of addressing medical education inequalities globally.\nWe expect our contributors to share the MSID website with their respective networks in order to raise awareness and expand our team.\nWe encourage medical institutions in difficulty to share this within their schools, but also across other institutions in difficulty, so that students can pursue their learning objectives during times of instability.",
      id: 5
    },
    {
      name: "Disclaimer",
      text: "The content of this website is, and remains, the intellectual property of the owners and operators of this website. You may not use, copy, reproduce, modify or distribute any content on this website for commercial purposes unless expressly provided with written permission from the owners and operators of this website.\nYou may not use, copy, reproduce, modify or distribute any content on this website in any way which is harmful, unlawful, illegal, abusive, harassing, threatening or otherwise objectionable, or in breach of any relevant law or regulation.\nWe endeavour to ensure that the information provided on this website reflects an acceptable medical standard However, all content (whether provided by us or a third party) is made available without any guarantees, conditions, warranties, or representations as to its accuracy, completeness or reliability. The content of our website is not intended to be advice upon which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our website. You should be aware that professionals in the field may have different opinions, as a result of their differing experiences. We strongly advise viewers to independently verify any information upon which they choose to rely.",
      id: 6
    },
  ]

    return (
      <>
      <Typography variant="h3" className="typewriter headers">About MSID</Typography>
      <Container maxWidth="lg">
      <p className="block-text">
      MSID are a team of over 300 healthcare professionals, educators and support staff from across the globe. We are driven by a passion for collaboration, evidence-based medicine and inclusion. We invest our time and expertise voluntarily towards a shared goal.
      <span className="spacer"></span>
Our vision is to eliminate educational inequalities in medical education.
<span className="spacer"></span>
Our mission is to make medical education accessible for learners unable to engage with normal learning opportunities. 
        </p>
        {cards.map((card, key) => {
          return(
            <Box key={card.id} 
            // sx={{display: 'flex', margin: '0 auto', justifyContent: 'center', alignItems: 'middle', flexDirection: 'row'}}
            // sx={{whiteSpace: 'pre'}}
            >
            <h1>{card.name}</h1>
            <code>{card.text}</code>
            </Box>
          )
        })}



      {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Planning</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          This is an innovative project, aimed at supporting medical schools in areas of conflict and/or natural disasters who are unable to provide onsite medical education safely, and do not currently have adequate online learning provision.
          <br/>
When teaching sessions are cancelled, medical students in difficulty across the globe are at risk of being unable to qualify as doctors.
<br/>
The MSID team have created an online learning platform focussing on curriculum topics to enable medical students to access and pursue their learning even during times of great difficulty.

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Development</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          The impetus for this project came in October 2023 when medical students from the Arab American University of Palestine (AAUP) asked for support as they were unable to see patients in healthcare settings owing to the safety risk.
<br/> 
The project saw over 300 volunteers work together to develop an online platform which is accessible to the medical students from a place of safety.
<br/>
Presenters from the MSID team each recorded a short (10-15 minute) video covering a patient presentation from the UK Medical Licensing Assessment (MLA) content map, covering BMJ Best Practice principles of history taking, physical examination, emergency considerations and differential diagnoses.
<br/>
The success of the MSID platform relies on collaboration between healthcare professionals, medical educators and IT professionals. 
<br/>
The platform includes a section where a variety of external resources have been compiled that may also support medical students in difficulty.
<br/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Quality Assurance</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          MSID has used the UK MLA content map to form the basis of all patient presentations. The MLA has been developed by the UK General Medical Council (GMC) and is considered the standard for medical education training in the UK.
<br/>
All video recordings are reviewed by GMC-registered UK specialists (Family Medicine or Hospital Consultants) to ensure the MSID website provides a high standard of quality in medical education provision.
<br/> 
The MSID team believes that learning is accessible when it is inclusive. Our team are from various cultures and countries, enabling us to form a truly unique and inclusive ‘community of practice’. 
<br/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>Sustainability</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          In October 2023, the MSID team identified 16 out of the 24 areas of clinical practice from the UK MLA as Priority Level 1 learning needs for medical students in difficulty, and these were addressed first on this learning platform.
          <br/>
In November 2023, the MSID team expanded its content to cover Priority Level 2 topics, thereby covering all of the 200+ patient presentations in the curriculum. 
          <br/>
This unique resource aspires to be comprehensive in order to anticipate and address the ongoing learning needs of medical students in difficulty. The contact form on this website enables healthcare professionals who are interested in contributing content to this platform to join our team and develop resources, which will now be focussed on  clinical and professional capabilities.

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>Dissemination</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We want visitors to this website to share this resource with learners in difficulty in order to achieve the MSID mission of addressing medical education inequalities globally.
          <br/>
We expect our contributors to share the MSID website with their respective networks in order to raise awareness and expand our team.
<br/>
We encourage medical institutions in difficulty to share this within their schools, but also across other institutions in difficulty, so that students can pursue their learning objectives during times of instability.
<br/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography>Disclaimer</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          The content of this website is, and remains, the intellectual property of the owners and operators of this website. You may not use, copy, reproduce, modify or distribute any content on this website for commercial purposes unless expressly provided with written permission from the owners and operators of this website.
<br/>
You may not use, copy, reproduce, modify or distribute any content on this website in any way which is harmful, unlawful, illegal, abusive, harassing, threatening or otherwise objectionable, or in breach of any relevant law or regulation.
<br/>
We endeavour to ensure that the information provided on this website reflects an acceptable medical standard However, all content (whether provided by us or a third party) is made available without any guarantees, conditions, warranties, or representations as to its accuracy, completeness or reliability. The content of our website is not intended to be advice upon which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our website. You should be aware that professionals in the field may have different opinions, as a result of their differing experiences. We strongly advise viewers to independently verify any information upon which they choose to rely.
<br/>
          </Typography>
        </AccordionDetails>
      </Accordion> */}
      </Container>
      </>
    );
  }
  export default Cards;