// import logo from './logo.svg';
// import ReactDOM from 'react-dom';
import { Route, Routes } from 'react-router-dom';

import './App.css';
import ReactGA from 'react-ga4';

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Cards from "./pages/About/Cards";
import Topics from "./pages/Topics/Topics";
import Contact from "./pages/Contact/Contact";
import Resources from "./pages/Resources/Resources";

// import RouteChangeTracker from './routeChangeTracker';

document.body.style.backgroundColor = '#1e2422'
function App() {
  ReactGA.initialize('G-CXG8R8Q363');
  return (
    <div className="App">
      <Navbar/>
      {/* <RouteChangeTracker /> */}
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/cards" element={<Cards />} />
      <Route path="/topics" element={<Topics />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/resources" element={<Resources />} />
    </Routes>
    <Footer/>
    </div>
  );
}

export default App;
