import {Container, Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import ReactGA from 'react-ga4';

// import useAnalyticsEventTracker from '../../useAnalyticsEventTracker';

function Contact() {
  ReactGA.send({ hitType: "pageview", page: "/contact", title: "Contact" });
  // const gaEventTracker = useAnalyticsEventTracker('Contact us');
    return (
      <>
      <Container maxWidth="lg" sx={{mb: '20%'}}>
      <Typography variant="h3" className="typewriter headers homeHeader">Contact Us</Typography>
      <Box>
        <img className="image-filter" src='map-msid.png' alt="map of the word" width="80%" />
      </Box>
      <p className="block-text">
      We welcome feedback from medical students who have used our website, and are eager to hear from healthcare professionals who would like to record or quality review presentations on clinical and professional capabilities from the UK Medical Licensing Assessment (MLA) content map.
<span className="spacer"></span>
Please click below to get in touch with a member of our team via email. 
        </p>
        <Button className="no-style" sx={{backgroundColor:"#ED6077", color: 'white'}} variant="contained" href="mailto:MedSchInDifficulty@gmail.com" endIcon={<SendIcon />}>
        Contact Us
      </Button>
      </Container>
      </>
    );
  }
  export default Contact;