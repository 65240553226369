import { useEffect, useState } from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { firebaseConfig } from '../../config/firebase';
import ReactGA from 'react-ga4';

import { Container } from '@mui/system';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

//MUI Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

import Resource from './Resource'

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

function Resources() {
  ReactGA.send({ hitType: "pageview", page: "/resources", title: "Resources" });
  const [selectedResource, setSelectedResource] = useState(null);
  const [resourceData, setResourceData] = useState([]);

  const fetchResourceData = async () => {
    try {
      const snapshot = await firebase.database().ref('/resources').once('value');
      const data = snapshot.val();
      setResourceData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchResourceData();
  }, []);

  const handleResourceClick = async (resource) => {
    setSelectedResource(resource);
  };

  const handleBackClick = () => {
    setSelectedResource(null);
    setResourceData([]);
    fetchResourceData();
  };
  const resources = [
    {name: 'General'}, 
    {name: 'Anatomy'},
    {name: 'Cardiovascular Physiology'}, 
    {name: 'Critical Care'}, 
    {name: 'Dermatology'}, 
    {name: 'Histology'}, 
    {name: 'Obstetrics & Gynaecology'}, 
    {name: 'Paediatrics'}, 
    {name: 'Pathology'}, 
    {name: 'Psychiatry'}, 
    {name: 'Radiology'}, 
    {name: 'Sexual & Reproductive Health'}, 
    {name: 'Surgery'}
    ]
    return (
      <>
      {!selectedResource ? (
      <Container maxWidth="lg" sx={{display: 'block', mt: 5}}>
             <Typography variant="h3" className="typewriter headers">External Resources</Typography>
        <p className="block-text">
        MSID has collated a list of freely available resources from external providers aimed at enhancing online medical education provision. 
        <span className="spacer"></span>
However, we make no assertions as to the accuracy of quality of these resources, which users access and rely on at their own risk.
        </p>
        {resources.map((resource, key) => {
        return(
          <>
        <Card className="cardHover" raised key={resource.name} sx={{ minWidth: 250, display: 'inline-block', padding: 3, m: 1, cursor:'pointer', borderRadius: '20px'}} onClick={() => handleResourceClick(resource)}>
        <CardContent>
        <AutoStoriesIcon />
          <Typography sx={{ fontSize: '1.3rem' }} gutterBottom>
            {resource.name}
          </Typography>
        </CardContent>
      </Card>
      </>
        )
      }
      )}
      </Container>
      ):
      (
        <>
        <Container sx={{mt: 5}}>
        <Button sx={{backgroundColor:"#ED6077"}} variant="contained" onClick={handleBackClick} startIcon={<ArrowBackIcon />}>
        Back
      </Button>
        {/* {selectedResource.component} */}
        { resourceData.length >0 &&  resourceData?.map((d,) => (
     selectedResource.name === d.title &&   <Resource key={d.title} resourceName={d.name} data={d.items} resourceTitle={d.title}/>

      ))}
        </Container>
        </>
      )}
      </>
    );
  }
  export default Resources;