import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    // minHeight: '100vh',
  },
  footer: {
    marginTop: 'auto',
    bottom: '0',
    position: 'fixed'
  },
};

function Footer() {
  return (
    <Box sx={styles.root}>
      <AppBar sx={{position: 'static',backgroundColor: '#35948F', mt: '2%', borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}>
        <Toolbar sx={{m: '0 auto',textAlign: 'center', fontSize: '14px'}} variant='dense'>
        Medical Schools in Difficulty. 2023
        </Toolbar>
      </AppBar>
    </Box>
  );
}
export default Footer;
