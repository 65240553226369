import { Container, Box } from '@mui/system';
import Typography from '@mui/material/Typography';

import ReactGA from 'react-ga4';

// import map from "placeholder-thumb.jpg";

function Home() {
  ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });
  return (
    <>
    <Container maxWidth="lg">
      <Typography variant="h3" className="typewriter headers homeHeader">Welcome to the MSID<br/>  Online Learning Platform</Typography>
      <Box>
        <img className="image-filter" src='map-msid.png' alt="map of the word" width="80%" />
      </Box>
      <p className="block-text">
      This unique platform is designed to make undergraduate medical education accessible to learners who are unable to engage in normal medical school teaching methods.
<span className="spacer"></span>
  In countries experiencing the devastating effects of conflict and/or natural disasters, medical students are unable to see patients and attend normal teaching due to safety concerns. Medical Schools in Difficulty (MSID) aims to fill this educational inequality gap. 
  <span className="spacer"></span>
  We have aligned our content to the UK medical schools’ Medical Licencing Assessment (MLA) content map which sets the standard for safe medical practice. The MSID learning platform covers all 24 Areas of Clinical Practice, and all 200+ patient presentations identified on the MLA content map.
<span className="spacer"></span>
Each patient presentation is delivered by a healthcare professional in a short video based around the BMJ Best Practice principles of history taking, physical examination, emergency considerations and common differential diagnoses.
        </p>

        <Box maxWidth="md" sx={{margin: "0 auto",backgroundColor: "#35948F", borderRadius:"10px", padding:"10px"}}>
        <Typography variant="body" sx={{fontStyle:"italic"}}>
        “I am a family doctor and head of the Family Medicine faculty department of Uzhgorod National University in Ukraine. I want to express my gratitude for your generous offer to use the MSID platform for doctors in need while in crisis.
        <br/>
        As a national representative of EURACT (the European Academy of Teachers in Family Medicine), I will be happy to share this important link with all universities of my country where the war is going on for over two years already.”
        </Typography>
        <span className="spacer"></span>
        <Typography variant='body'>
        - Dr. Pavlo Kolesnyk, Winner of the WONCA Europe 2023 5 Star Doctor Award
        </Typography>
      </Box>
      <span className="spacer"></span>
      <span className="spacer"></span>
      </Container>
    </>
  );
}
export default Home;