import React, { useEffect, useState } from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { firebaseConfig } from '../../config/firebase';
import ReactGA from 'react-ga4';

import { Container } from '@mui/system';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

//MUI Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmergencyShare from '@mui/icons-material/EmergencyShare';
import Coronavirus from '@mui/icons-material/Coronavirus';
import MonitorHeart from '@mui/icons-material/MonitorHeart';
import EscalatorWarning from '@mui/icons-material/EscalatorWarning';
import BlurOn from '@mui/icons-material/BlurOn';
import Hearing from '@mui/icons-material/Hearing';
import Attractions from '@mui/icons-material/Attractions';
import AirlineSeatFlat from '@mui/icons-material/AirlineSeatFlat';
import ElderlyWoman from '@mui/icons-material/ElderlyWoman';
import PsychologyAlt from '@mui/icons-material/PsychologyAlt';
import EmojiPeople from '@mui/icons-material/EmojiPeople';
import Psychology from '@mui/icons-material/Psychology';
import Female from '@mui/icons-material/Female';
import RemoveRedEye from '@mui/icons-material/RemoveRedEye';
import AllInclusive from '@mui/icons-material/AllInclusive';
import Air from '@mui/icons-material/Air';
import Diversity1 from '@mui/icons-material/Diversity1';
import Masks from '@mui/icons-material/Masks';
import Handyman from '@mui/icons-material/Handyman';
import Bloodtype from '@mui/icons-material/Bloodtype';
import VolunteerActivism from '@mui/icons-material/VolunteerActivism';
import Vaccines from '@mui/icons-material/Vaccines';
import Gradient from '@mui/icons-material/Gradient';
import Diversity2 from '@mui/icons-material/Diversity2';

import Topic from './Topic'

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
function Topics() {
  ReactGA.send({ hitType: "pageview", page: "/topics", title: "Topics" });
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [topicData, setTopicData] = useState([]);

  const fetchTopicData = async () => {
    try {
      const snapshot = await firebase.database().ref('/topics').once('value');
      const data = snapshot.val();
      setTopicData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchTopicData();
  }, []);
    
  const handleTopicClick = async (topic) => {
    setSelectedTopic(topic);
  };
  const handleBackClick = () => {
    setSelectedTopic(null);
    setTopicData([]);
    fetchTopicData();
  };

  const topics = [
    {name: 'Acute & Emergency', icon:<EmergencyShare/> }, 
    {name: 'Cancer', icon: <Coronavirus/>}, 
    {name: 'Cardiovascular', icon: <MonitorHeart/>}, 
    {name: 'Child Health', icon: <EscalatorWarning/>},
    {name: 'Clinical Haematology', icon:<Bloodtype/>},
    {name: 'Clinical Imaging', icon:<Gradient/>},
    {name: 'Dermatology', icon: <BlurOn/>}, 
    {name: 'Ear, Nose & Throat', icon: <Hearing/>}, 
    {name: 'End of Life Care', icon:<Diversity2/>},
    {name: 'Endocrine & Metabolic', icon: <Attractions/>}, 
    {name: 'Family Medicine', icon:<Diversity1/>},
    {name: 'Gastrointestinal',  icon:<AirlineSeatFlat/>}, 
    {name: 'Infection', icon:<Masks/>},
    {name: 'Older Adults', icon: <ElderlyWoman/>}, 
    {name: 'Mental Health', icon:<PsychologyAlt/>}, 
    {name: 'Musculoskeletal', icon: <EmojiPeople/>}, 
    {name: 'Neurosciences', icon:<Psychology/>}, 
    {name: 'Obs and Gynae', icon: <Female/>}, 
    {name: 'Ophthalmology', icon: <RemoveRedEye/>}, 
    {name: 'Perioperative', icon:<Vaccines/>},
    {name: 'Renal & Urology', icon:<AllInclusive/>}, 
    {name: 'Respiratory', icon:<Air/>},
    {name: 'Sexual Health', icon:<VolunteerActivism/>},
    {name: 'Surgery', icon:<Handyman/>},
]
    return (
      <>
      {!selectedTopic ? (
      <Container maxWidth="lg" sx={{display: 'block', mt: 5}}>
       <Typography variant="h3" className="typewriter headers">Areas of Clinical Practice</Typography>
        <p className="block-text">
        On this page you will find over 200 short videos, covering all the patient presentations listed on the UK Medical Licencing Assessment (MLA) content map. These are found under each of the 24 Areas of Clinical Practice.
        </p>
        <p className="block-text">
        Every video is presented by a healthcare professional, and has been reviewed by a UK General Medical Council (GMC) registered Family Medicine or Hospital Consultant specialist.
        </p>
<p className="block-text">
In order to make these videos applicable to all medical students in difficulty, we have chosen not to focus on investigations and management, which vary considerably across countries, depending on resources.
        </p>
      {topics.map((topic, key) => {
        return(
          <>
          
        <Card className="cardHover" raised key={topic.name} sx={{ minWidth: 250, display: 'inline-block', padding: 3, m: 1, cursor:'pointer', borderRadius: '20px'}} onClick={() => handleTopicClick(topic)}>
        <CardContent>
        {topic.icon}
          <Typography sx={{ fontSize: '1.3rem' }} gutterBottom>
            {topic.name}
          </Typography>
        </CardContent>
      </Card>
      </>
        )
      }
      )}
      </Container>
      ):
      (
        <>
        <Container sx={{mt: 5}}>
        <Button sx={{backgroundColor:"#ED6077"}} variant="contained" onClick={handleBackClick} startIcon={<ArrowBackIcon />}>
        Back
      </Button>
      {/* {selectedTopic.component({ data: topicData })} */}
      { topicData.length >0 &&  topicData?.map((d,) => (
     selectedTopic.name === d.title &&   <Topic key={d.title} topicName={d.name} data={d.items} topicTitle={d.title}/>

      ))}
        </Container>
        </>
      )}
      </>
    );
  }
  export default Topics;